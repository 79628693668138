<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('messagingTitle') }}</h3>
  </div>
  <message-form-wrapper
    v-if="license"
    :license="license"
  ></message-form-wrapper>
  <p>{{isLoading}}</p>
  <div class="position-relative" v-if="!license">
    <spinner-component item-class="spinner-layout-gray"></spinner-component>
  </div>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { store } from '@/application/store'

export default {
  name: 'MessagingPage',
  components: {
    'message-form-wrapper': defineAsyncComponent(() => import('@/application/components/elements/MessageFormWrapper.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  setup() {
    const license = computed(() => store.getters.getLicense)
    return {
      license,
    }
  },
}
</script>
